import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { UntypedFormBuilder, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
//import { MDBBootstrapModule } from "angular-bootstrap-md";
import { NgxContentLoadingModule } from "ngx-content-loading";
import { OwlModule } from "ngx-owl-carousel";
import { NgxPaginationModule } from "ngx-pagination";
import { CardLoaderComponent } from "./components/card-loader/card-loader.component";
import { NoAccessComponent } from "./components/no-access/no-access.component";
import { NoProductsFoundComponent } from "./components/no-products-found/no-products-found.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { AdminGaurd } from "./guards/admin-gaurd";
import { AuthGuard } from "./guards/auth_gaurd";
import { FilterByBrandPipe } from "./pipes/filterByBrand.pipe";
import { MomentTimeAgoPipe } from "./pipes/moment-time-ago.pipe";
import { TranslatePipe } from "./pipes/translate.pipe";
import { AuthService } from "./services/auth.service";
import { ProductService } from "./services/product.service";
import { UserService } from "./services/user.service";
@NgModule({
  imports: [
    CommonModule,
   // MDBBootstrapModule.forRoot(),
    FormsModule,
    HttpClientModule,
    RouterModule,
    OwlModule,
    NgxPaginationModule,
    NgxContentLoadingModule,
  ],
  declarations: [
    NoProductsFoundComponent,
    FilterByBrandPipe,
    NoAccessComponent,
    PageNotFoundComponent,
    TranslatePipe,
    CardLoaderComponent,
    MomentTimeAgoPipe,
  ],
  exports: [
    NoProductsFoundComponent,
    FormsModule,
    //MDBBootstrapModule,
    FormsModule,
    RouterModule,
    OwlModule,
    NgxPaginationModule,
    FilterByBrandPipe,
    NoAccessComponent,
    PageNotFoundComponent,
    TranslatePipe,
    MomentTimeAgoPipe,
    NgxContentLoadingModule,
    CardLoaderComponent,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    ScrollingModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    AdminGaurd,
    ProductService,
    UserService,
    UntypedFormBuilder,
  ],
})
export class SharedModule {}
